<template>
  <div class="border border-gray rounded-lg my-2 mx-normal">
    <div class="flex items-center text-left px-normal transition-all duration-100 pb-2" :class="selected && multi ? 'bg-selected' : 'bg-unselected' ">
      <div @click="handleRedirect" class="flex-none cursor-pointer rounded-full" >
        <div class="grid-thumb-image flex-initial align-middle left-0 self-center">
<!--          <div v-if="value.profile_picture !== null">-->
<!--            <img :src="value.profile_picture" alt="foodie"/>-->
<!--          </div>-->
<!--          <div v-else>-->
<!--            <img src="../../assets/logo.png" alt="default"/>-->
<!--          </div>-->
          <a-initials :name="value.name" desing-class="w-14 h-14" :src="value.profile_picture" :class="{'border-2 border-yellow_button': value?.is_premium && value?.has_products}" class="display-block"></a-initials>
          <div v-if="value?.is_premium && value?.has_products" class="flex items-end justify-end rounded-full w-auto ml-auto -mt-5">
            <i class="pi pi-star d-block rounded-full bg-yellow_button text-center" style="font-size: 12px;padding: 0.35rem"></i>
          </div>
        </div>
      </div>
      <div @click="handleRedirect" class="flex-auto cursor-pointer pl-small">
        <p class="font-normal" style="word-break: break-all;">{{ value.name }}</p>
        <p v-if="value.ig_username !== null" class="font-light" style="word-break: break-all !important;">{{ '@' + value.ig_username }}</p>
        <div v-if="user.isFoodie">
          <p class="text-sm text-gray break-words truncate overflow-hidden" style="max-width: 170px;"><i class="fas fa-map-marker-alt"></i> {{ value.address }}</p>
        </div>
      </div>
      <div v-if="user.isRestaurant">
        <div v-if="!multi" class="flex items-center">
          <div class="flex-none flex items-center border rounded-full w-8 h-8 align-middle mr-2">
            <p @click="markAsFavorite" class="active:text-red cursor-pointer flex-auto text-center">
              <i v-if="gridType === 'favorite'" class="text-lg align-middle align-middle"
                 :class="markFavorite
                 ? 'icon-heart text-red'
                 : 'icon-heartEmpty text-gray'"></i>
              <!--          <i  :class=" 'favorite' : 'unselected'" />-->
              <i v-else :class="markFavorite
               ? 'icon-heart text-red'
               : 'icon-heartEmpty text-gray'"
                 class="text-lg align-middle"></i>
              <!--            <i v-else class="icon-heartEmpty text-lg"></i>-->
            </p>
          </div>
          <div class="flex-none">
            <button @click="singleCollaboration" class="grid-button bg-primary text-sm uppercase" :class="value?.is_premium && value?.has_products ? 'bg-yellow_button text-black' : 'bg-primary text-white'">Colab</button>
          </div>
        </div>
        <div v-else class="flex items-center">
          <div @click="multiSelect" class="flex-none px-normal">
            <p class="cursor-pointer">
              <i class="icon-checkmark text-gray rounded-full border p-2 text-xl transition-all duration-100" :class="selected ? 'selected' : 'unselected'"/>
            </p>
          </div>
        </div>
      </div>
    </div>
<!--    <div v-if="user.isRestaurant && value.is_premium" class="py-1 bg-yellow_ribbon">-->
<!--      <p class="align-middle">Foodie premium</p>-->
<!--    </div>-->
    <div
      class="grid pt-3 border-gray items-end border-t"
      :class="{ 'grid-cols-3': value.completed_collabs_count,
      'grid-cols-2' : !value.completed_collabs_count}">
      <template v-if="user.isRestaurant">
        <div class="flex-1 flex-col flex-grow flex-wrap">
          <h4 class="font-bold text-lg">{{ value.ig_followers_count ? formattedFollowers(value.ig_followers_count) : 0 }}</h4>
          <h4 class="text-dgray">Followers</h4>
        </div>
        <div v-if="value.completed_collabs_count" class="flex-1 flex-col flex-grow flex-wrap">
          <h4 class="font-bold">{{ value.completed_collabs_count}}</h4>
          <h4 class="text-dgray">Colaboraciones</h4>
        </div>
        <div class="flex flex-col flex-grow flex-wrap">
          <h4 class="font-bold">{{ value.ig_engagement_prc + ' %'}}</h4>
          <h4 class="text-dgray">Engagement</h4>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'GridElement',
  props: {
    value: Object,
    gridType: String,
    multi: Boolean
  },
  data () {
    return {
      code: '',
      body: {
        attach: [],
        detach: []
      },
      selected: false,
      markFavorite: false,
      letFavorite: false,
      loadingFavorite: false
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'session/isAuthenticated'
    }),
    ...mapState({
      user: ({ session }) => session.user
    })
  },
  methods: {
    formattedFollowers (followers) {
      if (followers >= 1000000) {
        return parseFloat(followers / 1000000).toFixed(1) + 'M'
      } else if (followers >= 1100) {
        return parseFloat(followers / 1000).toFixed(1) + 'k'
      }
      return followers
    },
    multiSelect () {
      this.selected = !this.selected
      if (this.selected) {
        this.$emit('selectedFoodie', this.value.id)
      } else {
        this.$emit('unselectedFoodie', this.value.id)
      }
    },
    handleRedirect () {
      if (this.multi) {
        this.multiSelect()
      } else {
        this.$router.push({ path: `/explore/${this.value.id}` })
      }
    },
    singleCollaboration () {
      if (this.value.is_premium && this.value.has_products) {
        this.$router.push({ name: 'collaboration.create.premium', params: { id: this.value.id } })
      } else {
        this.$router.push({ path: `/collaboration/create/${this.value.id}` })
      }
      // console.log('on construction')
    },
    markAsFavorite () {
      this.markFavorite = !this.markFavorite
      if (this.$route.name === 'explore.favorites') {
        this.body.attach = []
        this.body.detach = []
        if (!this.letFavorite) {
          this.body.attach.push(this.value.id)
        } else {
          this.body.detach.push(this.value.id)
        }
        this.letFavorite = !this.letFavorite
        return this.$repository.restaurants
          .setFavorite(this.user.restaurant.id, this.body)
          .then(() => (this.$router.go()))
      } else {
        this.body.attach = []
        this.body.detach = []
        if (!this.letFavorite) {
          this.body.attach.push(this.value.id)
        } else {
          this.body.detach.push(this.value.id)
        }
        this.letFavorite = !this.letFavorite
        return this.$repository.restaurants
          .setFavorite(this.user.restaurant.id, this.body)
      }
    }
  },
  created () {
    if (this.user.isRestaurant) {
      if (this.$route.name === 'explore.favorites') {
        this.letFavorite = true
        this.markFavorite = true
      } else {
        this.markFavorite = this.value.favorite
        this.letFavorite = this.value.favorite
      }
    }
  }
}
</script>

<style scoped>

img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}
.icon-heartEmpty{
  color: #00000017;
  font-size: 18px;
}
.icon-heart{
  color: #FE4242;
  font-size: 18px;
}
.selected{
  @apply bg-primary text-white
}
.unselected{
  @apply bg-white border-gray text-white
}
.bg-selected{
  background-color: rgba(57, 126, 243, 0.1);
}
.bg-unselected {
  background-color: #ffffff;
}
.favorite {
  @apply text-red
}
.unfavorite {
  @apply text-gray
}
.testing-transition{
  transition: all 4s ease-in;
}

@keyframes a-premium-bg {
  0% { background-color: #F7D708; }
  50% { background-color: #ffe32e; }
  100% { background-color: #F7D708; }
}
.a-premium {
  animation: a-premium-bg 2.0s infinite;
}
</style>
